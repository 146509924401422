/* Sayısal alanlarda yukarı/aşağı okları kaldırmak için */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  -moz-appearance: textfield;
  appearance: none;
}

/* TextArea genişletilebilir olmasın (genel destek) */
textarea {
  resize: none; /* Genel tarayıcı desteği */
}

/* WebKit tarayıcıları (Chrome, Safari) için TextArea genişletilebilir olmasın */
textarea::-webkit-resizer {
  display: none; /* WebKit tabanlı tarayıcılarda köşe yeniden boyutlandırma kontrolünü kaldırır */
}
